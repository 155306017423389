import React from "react";
// import { Link } from "gatsby";
import carImage from "../assets/img/city-car.jpg";
import Header from "../components/appComponents/Header";

const BecomeAHost = () => {
  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
        backgroundImage: "linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)",
      }}
    >
      <Header />
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f3f4f6",
          padding: "16px",
        }}
      >
        {/* Main Heading */}
        <h2
          style={{
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "center",
            color: "#1f2937",
            marginTop: "60px",
          }}
        >
         Grow your business with Zyppys
        </h2>
        {/* Content Section */}
        <div
          style={{
            maxWidth: "800px",
            width: "100%",
            textAlign: "center",
          }}
        >
          {/* <h2 style={{ fontSize: "2rem", fontWeight: "600" }}>
            Become a Host on Zyppys: Rent Out Your Car & Earn
          </h2> */}
          <p
            style={{ fontSize: "1.3rem", marginTop: "16px", color: "#4b5563",textAlign: "left" }}
          >
           Zyppys’s app and web portal seamlessly connect licensed and insured chauffeur partners with India's elite clientele of discerning business and leisure travelers. Whether you need to fill gaps in your schedule or seek a reliable primary ride source, we've got you covered. Our competitive pricing aligns with local market rates, and our steadfast commitment to quality ensures fair compensation for chauffeurs while delivering exceptional service to our guests.
          </p>

          {/* Full-Width Image */}
          <div style={{ width: "100%", marginTop: "50px" }}>
            <img
              src={carImage}
              alt="Car Sharing"
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          {/* Get Started Button */}
          {/* <div style={{ marginTop: "24px" }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.zyppys.customer&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "#a138de",
                color: "white",
                padding: "12px 24px",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: "600",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                textDecoration: "none",
              }}
            >
              Download App
            </a>
          </div> */}

          {/* Steps Section */}
          {/* <div style={{ marginTop: "32px", textAlign: "left" }}>
            
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 1: Sign Up & Verify Your Account
              </h3>
              <ul
                style={{
                  paddingLeft: "24px",
                  marginTop: "8px",
                  color: "#4b5563",
                  fontSize: "1.3rem",
                }}
              >
                <li>Download the host mobile app.</li>
                <li>Complete the registration.</li>
                <li>
                  Upload KYC documents (government ID, driver’s license, address
                  proof).
                </li>
              </ul>
            </div>

           
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 2: Check Host & Car Eligibility
              </h3>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "1.3rem",
                  color: "#4b5563",
                }}
              >
                To list your car on Zyppys, you must meet the following
                criteria:
              </p>
              <h4
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "600",
                  marginTop: "12px",
                }}
              >
                Host Requirements:
              </h4>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>Must be at least 21 years old.</li>
                <li>
                  Own the car legally with valid registration & insurance.
                </li>
                <li>Agree to Zyppys' terms & conditions for car sharing.</li>
              </ul>
              <h4
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "600",
                  marginTop: "16px",
                }}
              >
                Car Requirements:
              </h4>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>
                  The car should be less than 7 years old and in good condition.
                </li>
                <li>
                  It must have valid registration (RC), insurance, and road
                  permits.
                </li>
                <li>
                  The vehicle should be clean, well-maintained, and roadworthy.
                </li>
              </ul>
            </div>

            
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 3: List Your Car on Zyppys
              </h3>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>
                  Enter car details (brand, model, fuel type, transmission,
                  etc.).
                </li>
                <li>
                  Upload high-quality photos of your vehicle from multiple
                  angles.
                </li>
                <li>
                  Set your rental price, availability, and your own Terms &
                  Conditions.
                </li>
              </ul>
            </div>

            
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 4: Vehicle Inspection & Approval
              </h3>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>
                  Zyppys will conduct a quick verification & quality check.
                </li>
                <li>
                  Once approved, your listing will go live on the platform for
                  renters to book.
                </li>
              </ul>
            </div>

            
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 5: Accept Bookings & Rent Your Car
              </h3>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>
                  Receive rental requests via the Zyppys dashboard or mobile
                  app.
                </li>
                <li>Approve or decline bookings based on your availability.</li>
                <li>Offer pickup/drop-off at a preferred location.</li>
                <li>
                  Ensure the car is clean, fueled, and in good condition before
                  handing it over.
                </li>
              </ul>
            </div>

            
            <div style={{ marginTop: "24px" }}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                Step 6: Earn & Get Paid
              </h3>
              <ul
                style={{
                  fontSize: "1.3rem",
                  paddingLeft: "24px",
                  color: "#4b5563",
                }}
              >
                <li>
                  Get payments directly from the customer during car pickup.
                </li>
                <li>
                  Zyppys takes a small commission (10-20%) depending on the plan
                  you choose.
                </li>
              </ul>
            </div>
          </div> */}

          {/* Why Host on Zyppys Section */}
          <div style={{ marginTop: "32px", textAlign: "left" }}>
            <h3
              style={{ fontSize: "2rem", fontWeight: "600", marginTop: "40px" }}
            >
              Why Partner With Zyppys?
            </h3>
          </div>

          <ul
            style={{
              paddingLeft: "24px",
              marginTop: "16px",
              color: "#4b5563",
              textAlign: "left",
              fontSize: "1.3rem",
            }}
          >
            <li>
              <strong>Access to a Premium Clientele</strong> – Connect with India’s elite business and leisure travelers, enhancing your visibility and reputation in the luxury market.
            </li>
            <li>
              <strong>Robust Technology Platform</strong> – Benefit from our user-friendly app and web portal, designed to streamline bookings and improve customer interactions.
            </li>
            <li>
              <strong>Competitive Pricing Model </strong> – Enjoy a pricing structure that is regularly aligned with local market rates, ensuring fair compensation while attracting more customers.
            </li>
            <li>
              <strong>Commitment to Quality Service</strong> – Join a network dedicated to delivering exceptional service, enhancing customer satisfaction and loyalty.
            </li>
            <li>
              <strong>Marketing and Promotion Support </strong> – Leverage our marketing efforts to increase your exposure and reach a broader audience, driving more bookings.
            </li>
            <li>
              <strong>Reliable Payments</strong> – Enjoy timely and secure payments, ensuring consistent cash flow and peace of mind for your business operations.
            </li>
          </ul>

          <div style={{ marginTop: "40px", textAlign: "left" }}>
            <strong style={{ fontSize: "1.2rem", color: "#1f2937" }}>
              {/* Start Earning Today! List Your Car on Zyppys Now! */}
              Partnering with Zyppys can elevate your business and provide unparalleled opportunities for growth in the premium car rental market!
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeAHost;
